import React, { lazy } from 'react';
import { dashboardMenu, demoPages } from '../menu';
import Login from '../pages/presentation/auth/Login';
import ForgetPassword from '../pages/presentation/auth/ForgetPassword';
import ResetPassword from '../pages/presentation/auth/ResetPassword';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/dashboard/DashboardPage')),
};
const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};
const USER = {
	NEWUSER: lazy(() => import('../pages/presentation/UserPages/AddNewUser')),
	LISTUSERS: lazy(() => import('../pages/presentation/UserPages/ListAllUsers')),
	PRODUCTS_VIEW: lazy(() => import('../pages/presentation/UserPages/ViewUser')),
};

const JOBSITE = {
	NEWJOBSITE: lazy(() => import('../pages/presentation/Jobsites/AddNewJobsite')),
	LISTJOBSITES: lazy(() => import('../pages/presentation/Jobsites/ListAllJobsites')),
	EDITJOBSITE: lazy(() => import('../pages/presentation/Jobsites/ViewJobsites')),
	SUMMARY: lazy(() => import('../pages/presentation/Jobsites/Summary')),
	JSSUMMARY: lazy(() => import('../pages/presentation/Jobsites/JobSummary')),
	EMPLOYEEJOBSITE: lazy(() => import('../pages/presentation/Jobsites/EmployeeJobsites')),
	MERGEJOBSITE: lazy(() => import('../pages/presentation/Jobsites/MergeJobsites')),
	ACCEPTEMPLOYEEJOBSITE: lazy(() =>
		import('../pages/presentation/Jobsites/AcceptEmployeeJobsite'),
	),
};

const TASKS = {
	NEWTASK: lazy(() => import('../pages/presentation/Tasks/AddNewTask')),
	LISTTASK: lazy(() => import('../pages/presentation/Tasks/ListAllTasks')),
	EDITTASK: lazy(() => import('../pages/presentation/Tasks/EditTasks')),
	EDITTASKCOMMENT: lazy(() => import('../pages/presentation/Tasks/EditTaskComments')),
	ADDTASKCOMMENT: lazy(() => import('../pages/presentation/Tasks/AddTaskComments')),
};

const VEHICLES = {
	NEWVEHICLE: lazy(() => import('../pages/presentation/Vehicles/AddNewVehicle')),
	LISTVEHICLE: lazy(() => import('../pages/presentation/Vehicles/ListAllVehicles')),
	EDITVEHICLE: lazy(() => import('../pages/presentation/Vehicles/EditVehicle')),
};

const MATERIAL = {
	NEWMATERIAL: lazy(() => import('../pages/presentation/Material/AddMaterial')),
	LISTMATERIAL: lazy(() => import('../pages/presentation/Material/ListAllMaterials')),
	EDITMATERIAL: lazy(() => import('../pages/presentation/Material/EditMaterial')),
};

const PAYROLL = {
	TIMESHEETS: lazy(() => import('../pages/presentation/Payroll/Timesheets')),
	EMPTIMESHEETS: lazy(() => import('../pages/presentation/Payroll/EmployeeTimesheet')),
	EDITTIMESHEETS: lazy(() => import('../pages/presentation/Payroll/EditTimesheet')),
	ADDNEWTIMESHEET: lazy(() => import('../pages/presentation/Payroll/AddNewTimesheet')),
};
// const PAGE_LAYOUTS = {
// 	HEADER_SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/HeaderAndSubheader')),
// 	HEADER: lazy(() => import('../pages/presentation/page-layouts/OnlyHeader')),
// 	SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/OnlySubheader')),
// 	CONTENT: lazy(() => import('../pages/presentation/page-layouts/OnlyContent')),
// 	BLANK: lazy(() => import('../pages/presentation/page-layouts/Blank')),
// 	ASIDE: lazy(() => import('../pages/presentation/aside-types/DefaultAsidePage')),
// 	MINIMIZE_ASIDE: lazy(() => import('../pages/presentation/aside-types/MinimizeAsidePage')),
// };

const presentation = [
	/**
	 * Landing
	 */
	{
		path: dashboardMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
		exact: true,
	},
	{
		path: '/auth-pages/404',
		element: <AUTH.PAGE_404 />,
		exact: true,
	},
	{
		path: '/',
		element: <Login />,
		exact: true,
	},
	{
		path: '/forget-password',
		element: <ForgetPassword />,
		exact: true,
	},
	{
		path: '/reset-password/token/:token',
		element: <ResetPassword />,
		exact: true,
	},
	{
		path: demoPages.UserPages.subMenu.listUsers.path,
		element: <USER.LISTUSERS />,
		exact: true,
	},
	{
		path: demoPages.UserPages.subMenu.addUsers.path,
		element: <USER.NEWUSER />,
		exact: true,
	},

	{
		path: `/user-pages/editUsers/:id`,
		element: <USER.PRODUCTS_VIEW />,
		exact: true,
	},
	{
		path: demoPages.Jobsites.subMenu.addJobsite.path,
		element: <JOBSITE.NEWJOBSITE />,
		exact: true,
	},
	{
		path: demoPages.Jobsites.subMenu.viewJobsite.path,
		element: <JOBSITE.LISTJOBSITES />,
		exact: true,
	},
	{
		path: demoPages.Jobsites.subMenu.summary.path,
		element: <JOBSITE.SUMMARY />,
		exact: true,
	},
	{
		path: `/job-sites/summary/:id`,
		element: <JOBSITE.JSSUMMARY />,
		exact: true,
	},
	{
		path: demoPages.Jobsites.subMenu.employeeJobsite.path,
		element: <JOBSITE.EMPLOYEEJOBSITE />,
		exact: true,
	},
	{
		path: `/job-sites/editJobsite/:id`,
		element: <JOBSITE.EDITJOBSITE />,
		exact: true,
	},
	{
		path: `/job-sites/mergeJobsite/:id`,
		element: <JOBSITE.MERGEJOBSITE />,
		exact: true,
	},

	{
		path: `/job-sites/acceptEmployeeJobsite/:id`,
		element: <JOBSITE.ACCEPTEMPLOYEEJOBSITE />,
		exact: true,
	},
	{
		path: demoPages.Tasks.subMenu.addTasks.path,
		element: <TASKS.NEWTASK />,
		exact: true,
	},
	{
		path: demoPages.Tasks.subMenu.viewTasks.path,
		element: <TASKS.LISTTASK />,
		exact: true,
	},
	{
		path: `/task-pages/EditTasks/:id`,
		element: <TASKS.EDITTASK />,
		exact: true,
	},
	{
		path: `/task-pages/EditTaskComments/:id`,
		element: <TASKS.EDITTASKCOMMENT />,
		exact: true,
	},
	{
		path: `/task-pages/AddTaskComments/:id`,
		element: <TASKS.ADDTASKCOMMENT />,
		exact: true,
	},
	{
		path: demoPages.Vehicle.subMenu.addVehicle.path,
		element: <VEHICLES.NEWVEHICLE />,
		exact: true,
	},
	{
		path: demoPages.Vehicle.subMenu.viewVehicle.path,
		element: <VEHICLES.LISTVEHICLE />,
		exact: true,
	},
	{
		path: `/vehicle-pages/editVehicle/:id`,
		element: <VEHICLES.EDITVEHICLE />,
		exact: true,
	},
	{
		path: demoPages.Material.subMenu.addMaterial.path,
		element: <MATERIAL.NEWMATERIAL />,
		exact: true,
	},
	{
		path: demoPages.Material.subMenu.viewMaterial.path,
		element: <MATERIAL.LISTMATERIAL />,
		exact: true,
	},
	{
		path: `/material-pages/editMaterial/:id`,
		element: <MATERIAL.EDITMATERIAL />,
		exact: true,
	},
	{
		path: demoPages.Payroll.subMenu.timesheets.path,
		element: <PAYROLL.TIMESHEETS />,
		exact: true,
	},
	{
		path: `/payroll-pages/employee-timesheet/:id`,
		element: <PAYROLL.EMPTIMESHEETS />,
		exact: true,
	},
	{
		path: `/payroll-pages/editTimesheet/:id`,
		element: <PAYROLL.EDITTIMESHEETS />,
		exact: true,
	},
	{
		path: demoPages.Payroll.subMenu.addNewTimesheet.path,
		element: <PAYROLL.ADDNEWTIMESHEET />,
		exact: true,
	},
];
const contents = [...presentation];

export default contents;
